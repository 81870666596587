import "./jquery-3.6.3.min.js";
import "./flatpickr.js";

import ImageMap from "image-map";

import jQuery from "jquery";
window.$ = jQuery;

import flatpickr from "flatpickr";
import "./flatpickr_ko.js";


$(function(){

    $(window).scroll(function () {
        $(window).scrollTop() >= 50 ? $(".top-btn").show() : $(".top-btn").hide()
    })

    $('.top-menu>li').on({
        click:(e) => {
            $('.sub-menu').fadeOut(100)
            $(e.currentTarget).find('.sub-menu').stop().fadeToggle(100)
        },
        mouseenter:(e) => {
            $('.sub-menu').fadeOut(100)
            $(e.currentTarget).find('.sub-menu').stop().fadeIn(100)
        },
        mouseleave:(e) => {
            $(e.currentTarget).find('.sub-menu').stop().fadeOut(100)
        }
    });
    
    $('.m-nav>li').click((e) => {
            $('.nav-list').slideUp(500)
            $(e.currentTarget).find('.nav-list').stop().slideToggle(500)
    });

    ImageMap('img[usemap]')

    let thisPage = $('.wrapper').data('page');
    let $tabMenu = $('.tab-menu>li');
    let $subPage =  $('.page-menu li');
    let imgUrl =  'http://img.thewhitehotel.kr/';
    let imgList = {
        // hotel
        standPlus: {folder: 'room', len: 4},
        standDouble: {folder: 'room', len: 4},
        standQuard: {folder: 'room', len: 4},
        standOndol: {folder: 'room', len: 4},
        deluxeFamily: {folder: 'room', len: 4},
        deluxeTwin: {folder: 'room', len: 4},
        deluxeDouble: {folder: 'room', len: 4},
        deluxeOndol: {folder: 'room', len: 4},
        juniorOne: {folder: 'room', len: 4},
        juniorTwo: {folder: 'room', len: 5},
        whiteTwo: {folder: 'room', len: 6},
        whiteThree: {folder: 'room', len: 5},
        // terrace
        terraceSuite: {folder: 'room', len: 8},
        // villa
        villaDuflex: {folder: 'room', len: 9},
        villaSuite: {folder: 'room', len: 7},
        // gallery
        room: {folder: 'gallery', len: 14},
        dining: {folder: 'gallery', len: 5},
        facil: {folder: 'gallery', len: 5},
        event: {folder: 'gallery', len: 15},
        vid: { len: 0},
        // gallery sub
        roomSub: {folder: 'gallery/room/', len: 14},
        diningSub: {folder: 'gallery/dining/', len: 5},
        facilSub: {folder: 'gallery/facil/', len: 5},
        eventSub: {folder: 'gallery/event/', len: 15},
        vidSub: {len: 0},
        // dining
        lafore: {folder: 'dining', len: 2},
        delt: {folder: 'dining', len: 4},
        bistro: {folder: 'dining', len: 3},
        barbecue: {folder: 'dining', len: 3},
        // meeting
        lafrerie: {folder: 'meeting', len: 1},
        ladonis: {folder: 'meeting', len: 2},
        laste: {folder: 'meeting', len: 1},
        // fac
        business: {folder: 'fac', len: 2},
        fitness: {folder: 'fac', len: 2},
        cu: {folder: 'fac', len: 1},
    };

    let subSwipers = new Swiper('.subSwiper', {
        spaceBetween: 0,
            slidesPerView: 5,
            freeMode: true,
            watchSlidesProgress: true,
            loop: true,
    });

    let swp = {
        gallery: {
            loop: true,
            autoplay: {
                    delay: 2500,
                    disableOnInteraction: false,
                },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            thumbs: {
                swiper: subSwipers,
            },
        },
        default: {
            loop: true,
            autoplay: {
                    delay: 2500,
                    disableOnInteraction: false,
                },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
            },
        },

    }

    let sitemap = {
        'room-hotel-stand' : 'Superior',
        'room-hotel-deluxe' : 'Deluxe',
        'room-hotel-junior' : 'Junior Suite',
        'room-hotel-white' : 'White Suite',
        'standPlus' : '슈페리어 패밀리 트윈',
        'standDouble' : '슈페리어 더블',
        'standQuard' : '슈페리어 쿼드',
        'standOndol' : '슈페리어 온돌',
        'deluxeFamily' : '디럭스 패밀리트윈',
        'deluxeTwin' : '디럭스 트윈',
        'deluxeDouble' : '디럭스 더블',
        'deluxeOndol' : '디럭스 온돌',
        'juniorOne' : '주니어 스위트 더블',
        'juniorTwo' : '주니어 스위트 트윈',
        'whiteTwo' : '화이트 스위트 트윈',
        'whiteThree' : '화이트 스위트 패밀리',
        'villaSuite' : '빌라 스위트',
        'villaDuflex' : '빌라 듀플렉스',
        'delt' : '화이트 델리',
        'bistro' : '피자앤치킨',
        'lafore' : '레스토랑',
        'delt' : '화이트 델리',
        'barbecue' : '바베큐장',
        'Laprairie' : '라프레리(대연회장)',
        'Ladonis' : '라도니스(소연회장)',
        'Laster' : '라스떼(Conference Room)',
        'business' : '비즈니스 센터&라이브러리',
        'fitness' : '피트니스 센터',
        'cu' : '편의점',
    }

    // url query중 category 값이 있는지 검사한다.
    const params = new URLSearchParams(window.location.search).get('category');
    const findElement = document.querySelector(`[data-category="${params}"]`) || $('.page-menu > li:first-child')[0];
    const mainSwipers = new Swiper('.' + thisPage + 'Swiper', swp[thisPage === 'gallery' ? 'gallery' : 'default']);

    const createSwiper = (swiper, v) => {
        for(let i = 1; i <= imgList[v].len; i++) {
            let content = '';
            content += '<div class="swiper-slide">';
            content += '<img src="' + imgUrl + imgList[v].folder + '/' + v + (i <= 9 ? '/0' : '/') + i + '.jpg" class="img"/>';
            content += '</div>';
            swiper.appendSlide(content);
        }
    }

    const onTabClick = e => {
        let curTarget =  $(e.currentTarget); 
        let curCategory = curTarget.data('sub-category');

        // 예외 - 빌라의 경우, 1 카테고리 2 서브 카테고리라서 예외 처리 필요
        if (params === 'room-villa-duplex' && curCategory === 'villaSuite') {
            curTarget = curTarget.next();
        }

        // 서브 카테고리 값 변경
        $(".site-map span").last().text(sitemap[curTarget.data('sub-category')]);

        let tabImg = curTarget.data('img');
        $tabMenu.removeClass('active');
        curTarget.addClass('active');
        $('.contents > .active > div').removeClass('active');
        $('.' + tabImg).addClass('active');
        mainSwipers.removeAllSlides();
        createSwiper(mainSwipers, tabImg);
    }

    const onPageClick = e => {
        let curTarget =  $(e.currentTarget); 
        let subImg = curTarget.data('img');
        $('.contents > div').removeClass('active');
        $('.' + subImg).addClass('active');
        $subPage.removeClass('active');
        curTarget.addClass('active');

        // 사이트맵 수정
        let curCategory = curTarget[0]?.dataset.category;

        if (curCategory) {
            let getChild;
            const startsWithRoom = curCategory.startsWith('room-');
            
            if (startsWithRoom) {
                // room- 으로 시작하는 페이지라면
                getChild = $(".site-map span").eq(-2);
            } else {
                getChild = $(".site-map span").last();
            }
            getChild.text(sitemap[curCategory]);
        }

        switch(thisPage) {
            case "gallery":
                mainSwipers.removeAllSlides();
                createSwiper(mainSwipers, subImg);
                subSwipers.removeAllSlides();
                createSwiper(subSwipers, subImg + 'Sub');
                break;
            case "rooms":
                $('.' + subImg + ' .tab-menu > li:first-child').click();
                break;
            case "none":
                break;
            default:
                mainSwipers.removeAllSlides();
                createSwiper(mainSwipers, subImg);
                break;
        }
    }

    $tabMenu.click(e => onTabClick(e));
    $subPage.click(e => onPageClick(e));

    //처음 슬라이드 생성
    let firstSwiper = {currentTarget: findElement};

    if (firstSwiper.currentTarget) {
        onPageClick(firstSwiper);
    }

    flatpickr.localize(flatpickr.l10ns.ko);
    flatpickr(".sdate", {
        minDate: "today",
        defaultDate: "today",
        disableMobile: "true",
        local: 'ko'
    })

    // formcheck
    $(document).on("keyup", ".phone", function() {
        prev    = $(this).val();
        str     = prev.replace(/[^0-9]/g, "").replace(/(^02|^0505|^01.{1}|^0[0-9]{2})([0-9]+)?([0-9]{4})/,"$1-$2-$3").replace("--", "-");
        $(this).val(str);
    });
    
    const telCheck = (v) => {
        var reg = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
        if(!reg.test($(".phone").val())) {
            alert("올바른 휴대폰 번호를 입력해주세요.");
            $(".phone").focus();
            return true;
        }
    }
    
    const formCheck = ($form) => {
        const $input = $form.find('input, select, textarea');
        if ($form) {
            $($input).each((i,v) => {
                if(v.value === '' && $(v).data('optional') != "skip") {
                    alert($(v).data('check'));
                    $(v).focus();
                    return false;
                }
                if($(v).hasClass('phone') && !telCheck(v)){}
            });
        } 
    }

    // notice
    $('.brd-cont .btn-copy.mail').click(() => {
        let copyText = $('.copytext');
        copyText.select();
        document.execCommand("copy");
        alert('접수용 메일주소가 복사되었습니다.');
    })
    $('.brd-cont .btn-copy.link').click(() => {
        let copyText = $('.copytext.link');
        copyText.select();
        document.execCommand("copy");
        alert('주소가 복사되었습니다.');
    })

    // dining
    $('.sms-wrapper .btn-box').click(()=>{
        const $form = $('#sms');
        formCheck($form)
        
        $.ajax({		
        	type: "post",
         	url: "/facilities",
        	data: $("#sms").serialize(),
         	success: function(r) {
         		alert("안내문자가 발송되었습니다.");
         		self.close();
         	}
        });
    })
    // evt
    const fstSwiper = new Swiper('.festival-wrapper .swiper-container', {	
        effect: "fade",
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
    })
})
